import Color from 'colorjs.io'

import { extendTheme } from "@mui/joy";

const ALOADED_THEME = {
  brandPrimary: "#555555",
  brandPrimaryColor: "white",
  borderColor: 'CanvasText',
  borderWidth: '2pt',
  borderRadius: 0
}

const WHITE_LABEL_THEME = {
  brandPrimary: "#EFCEBA",
  brandPrimaryColor: "white",
  borderColor: '#ccc',
  borderWidth: '2px',
  borderRadius: 0
}

export default function buildTheme({
  themeType = 'ALOADED',
  brandPrimary = '#EFCEBA',
  brandPrimaryColor = 'black'
}) {
  if (themeType === 'ALOADED') {
    return createTheme({
      ...ALOADED_THEME,
      themeType
    })
  } else {
    return createTheme({
      ...WHITE_LABEL_THEME,
      brandPrimary,
      brandPrimaryColor
    })
  }
}

export function createTheme({
  brandPrimary,
  brandPrimaryColor,
  themeType,
  borderColor,
  borderWidth,
  borderRadius
}) {
  document.documentElement.style.setProperty('--brand-primary-background', brandPrimary);
  document.documentElement.style.setProperty('--brand-primary-color', brandPrimaryColor);
  return extendTheme({
    shadow: {
      xs: 'none',
      md: 'none',
      lg: 'none'
    },
    radius: {
      xs: 0,
      md: 0,
      lg: 0,
      sm: 0
    },
    variants: {
      outlined: {
        borderWidth,
        primary: {
          '--variant-borderWidth': borderWidth, 
        },
        neutral: {
          '--variant-borderWidth': borderWidth, 
        }
      }
    },
    colorSchemes: {
      dark: {
        palette: {
          background: {
            surface: 'black' 
          },
          primary: {
            solidBg: themeType === 'ALOADED' ? 'white' : brandPrimary,
            outlinedBorder: brandPrimary,
            solidColor: brandPrimaryColor,
            solidHoverBg: new Color(brandPrimary).lighten(.1).toString(),
            solidActiveBg: new Color(brandPrimary).darken(.1).toString(),
            outlinedColor: 'CanvasText',
            mainChannel:  brandPrimary
          },
          neutral: {
            outlinedBorder: themeType === 'ALOADED' ? 'white' : borderColor
          },
          common: {
            borderColor: themeType === 'ALOADED' ? 'white' : borderColor,
            solidBg: brandPrimary,
            solidHoverBg: new Color(brandPrimary).lighten(.5).toString(),
            solidActiveBg: new Color(brandPrimary).lighten(.5).toString(),
            mainChannel:  brandPrimary
          }
        }
      },
      light: {
        palette: {
          background: {
            surface: 'transparent' 
          },
          primary: {
            solidBg: brandPrimary,
            softBg: brandPrimary,
            outlinedBorder: brandPrimary,
            solidColor: brandPrimaryColor,
            solidHoverBg: new Color(brandPrimary).lighten(.05).toString(),
            solidActiveBg: new Color(brandPrimary).darken(.05).toString(),
            outlinedColor: 'CanvasText',
            mainChannel:  brandPrimary
          },
          body: {
            solidBg: 'var(--joy-palette-background-body)',
            solidColor: 'var(--joy-palette-nuetral-solidColor)'
          },
          common: {
            solidBg: brandPrimary,
            solidHoverBg: new Color(brandPrimary).lighten(.05).toString(),
            solidActiveBg: new Color(brandPrimary).lighten(.05).toString(),
            mainChannel:  brandPrimary
          },
          neutral: {
            outlinedBorder: borderColor
          },
          focusVisible: 'rgba(3, 102, 214, 0.3)',
        },
      },
    },
    focus: {
      default: {
        outlineWidth: '0px',
        border: `var(--variant-borderWidth) solid var(--variant-borderColor)`,
      },
    },
    fontWeight: "fontWeightMedium",
    fontFamily: {
      body: "DM Sans",
      display: "DM Sans"
    },
    components: {
      FeatureCard: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            borderColor,
            borderWidth,
            borderStyle: 'solid',
          })
        }
      },
      JoyLink: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            color: theme.vars.palette.common.black
          })
        }
      },
      JoyBox: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '&.ImageFrame': {
              borderColor,
              borderWidth,
              borderStyle: 'solid'
            },
            '&.links': {
              'a': {
                color: theme.vars.palette.common.black
              }
            }
          })
        }
      },
      JoySheet: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '&.Sidebar': {
              borderRightColor: borderColor,
              borderRightWidth: borderWidth,
              borderRightStyle: 'solid',
              //'--joy-palette-text-primary': color,
              boxShadow: '0 0 2pt rgba(0, 0, 0, .01)',
              "& a": {
                textDecoration: 'none'
              }
            },
            backgroundColor: theme.vars.palette.background.body
          })
        }
      },
      JoyListItemButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            '&.Mui-selected': {
              background: themeType === 'ALOADED' ? '#C0C0C0' : brandPrimary,
              // color: brandPrimaryColor
            }
          })
        }
      },
      JoyCard: {
        styleOverrides: {
          root: ({ ownerState }) => ({
              textDecoration: 'none',
            '& .MuiTypography-root': {
              //color,
              textDecoration: 'none'
            },
            '& a': {
              textDecoration: 'none'
            },
            '--variant-borderWidth': '2pt',
            marginBottom: 15,
            elevation: 0
          })
        }
      },
      JoyModalDialog: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({ 
            "border": "none",
            background: theme.vars.palette.background.body
          })
        }
      },
      JoyModal: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            "& > .MuiModal-backdrop": {
              "backdrop-filter": "none",
              "--joy-palette-background-backdrop": "rgba(0, 0, 0, .5)"
            }
          })
        }
      },
      JoyTable: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            "--TableCell-paddingX": '1.4rem',
            "--TableCell-paddingY": '1.4rem',
            "& th": {
              "opacity": 0.5,
              fontSize: '0.6rem',
              "padding": 8,
              "textTransform": "uppercase"
            },
            "& tr:focus": {
              "outline": "2pt solid black"
            },
            "& tr:hover td": {
            //  "background": "rgba(127, 127, 127, .1)"
            },
            "--TableCell-headBackground": "transparent",
            "fontWeight": 500
          })
        }
      },      
      JoyContainer: {
        styleOverrides: {
          root:  ({ ownerState }) => ({
            marginLeft: 1
          })
        }
      },
      JoyInput: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            minHeight: 30,
            fontSize: '14px',
            fontWeight: '500 !important',
            background: 'background.body',
            '&.Mui-focused': {
              outline: 'none'
            }
          })
        }
      },
      JoyAutocomplete: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            background: 'background.body',
            fontFamily: 'DM Mono',
            padding: 12
          })
        }
      },
      ALabel: {
        styleOverrides: {
          root: ({ ownerState }) => ({ 
            background: 'transparent'
          })
        }
      },
      JoyChip: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            borderRadius: 0,
            fontWeight: 'bold',
            fontSize: 10
          })
        }
      },
      JoySelect: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            fontSize: '14px',
            fontWeight: '500 !important',
            minHeight: 0,
            background: theme.vars.palette.background.body
          })
        }
      },
      JoyAutocomplete: {
        styleOverrides: {
          root: ({ ownerState }) => ({  
            '.MuiAutocomplete-popupIndicator, .MuiAutocomplete-popupIndicatorOpen': {
              margin: '0 !important',
              marginRight: '0 !important',
              alignSelf: 'stretch',
              /*borderRight: 'none',*/
              borderLeftColor: borderColor + ' !important',
              borderLeftWidth: borderWidth + ' !important',
              borderLeftStyle: 'solid !important',
            },
            '.MuiAutocomplete-popupIndicatorOpen': {
              borderLeftColor: 'none !important',
              borderLeftWidth: 'none !important',
              borderLeftStyle: 'none !important',
              borderRightColor: borderColor + ' !important',
              borderRightWidth: borderWidth + ' !important',
              borderRightStyle: 'solid !important',
            }
          })
        }
      },
      JoyButton: {
        styleOverrides: {   
          root: ({ ownerState }) => ({
            '&.MuiButton-variantPlain': {
              '&:hover': {
                background: 'unset',
                opacity: 1,
              },
              '&:active': {  
                background: 'unset',
                opacity: 0.5
              }
            },
            '&.MuiButton-variantOutlined': {
              '&:hover': {
                background: 'unset',
                opacity: 1,
              },
              '&:active': {  
                background: 'unset',
                opacity: 0.5
              }
            },
            '&:hover': {
            },
            '&:active': {
            },
            minHeight: 0,
            fontWeight: 600,
            padding: '4px 8px'
          })
        },
      }
    },
  })
}