import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/joy';
import { X } from '@phosphor-icons/react';

export function NotAuthorizedPage() {
    return (
        <Box sx={{ position: 'fixed', left: 0, top: 0, gap: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <X size={128} />
            <Typography level="h1" element="h1">Sorry, it seems you don't belong to this label</Typography>
            <Typography>If this is wrong, please contact the label support</Typography>
            <Button variant="solid" as={Link} to="/accounts/logout">Log out</Button>
        </Box>
    )
}
