import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Container, Box, Typography, Avatar, styled, Dropdown, MenuItem, Menu, MenuButton } from '@mui/joy';
import i18n from '@/i18n'
import { Bell } from '@phosphor-icons/react';

const AppHeaderRoot = styled(
  Box,
  {
    slot: 'root',
    name: 'AppHeader'
  }
)(({ theme }) => ({
  borderBottomWidth: theme.variants.outlined.borderWidth,
  borderBottomColor: theme.variants.outlined.neutral.borderColor,
  borderBottomStyle: 'solid',
  display: 'flex',
  padding: 1,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1
}))

export function AppHeader({
  user
}) {
  const navigate = useNavigate()
  return (
    <AppHeaderRoot>
      <Container>
        <Box sx={{ display: 'flex', padding: 1, flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Box sx={{ flex: 1 }} />
          {/*<Bell color="var(--brand-primary-background)" weight="bold" size={28} />*/}
          <Dropdown>
            <MenuButton variant="plain" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <Typography>{user?.username ?? 'user'}</Typography>
              <Avatar src={user?.imageUrl} />
            </MenuButton>
            <Menu>
              {/*<MenuItem onClick={() => navigate('/dashboard/profile')}>{i18n.t('profile')}</MenuItem>*/}
              <MenuItem onClick={() => navigate('/accounts/logout')}>{i18n.t('logout')}</MenuItem>
            </Menu>
          </Dropdown>
        </Box>
      </Container>
    </AppHeaderRoot>
  )
}