import React, { createContext, useState } from 'react'
import { useInfiniteResource, useLocalStorage } from '@/hooks'

import { useNavigate } from 'react-router-dom'
 
import { Select, Typography, Container, Box, Button, Input, Avatar, MenuButton, IconButton, Menu, MenuItem, Option, } from '@mui/joy' 
import { ViewTypeSelect } from './ViewTypeSelect'

import i18n from '@/i18n'
import { useHostname } from '@/contexts'
import { useContext } from 'react'
import { NodeCard } from './NodeCard'
import { CaretDown, CaretUp, Eye, MagnifyingGlass, MusicNote, Pencil, Plus, Trash } from '@phosphor-icons/react'
import { Loading } from './Loading'
import { useEffect } from 'react'
import { Case, Do, Empty, ForEach, SwitchCase } from './Syntax'
import { Grid, Table, Skeleton } from '@mui/joy'
import { Link } from 'react-router-dom'
import { Dropdown } from '@mui/joy'
import { MoreHoriz } from '@mui/icons-material'
import { EmptyView } from './EmptyView'
import { Img } from './Img'

export const GenericListContext = createContext({
  nodes: [],
  q: '',
  setQ: () => {},
  viewType: '',
  setViewType: () => {},
  columns: [],
  sortBy: '',
  setSortBy: () => {},
  path: ''
})
 
export function GenericList({
  queryKey,
  path,
  avatar = false,
  Icon = MusicNote,
  baseUrl,
  showImages=false,
  addUrl,
  sortByFields=[],
  showDefaultColumns=true,
  heading,
  canChangeSortBy=true,
  canSelectViewType=true,
  addLabel,
  filterPlaceholder,
  emptyText = 'Nothing found',
  columns=[],
  NodeCardComponent=NodeCard,
  viewType: defaultViewType = 'grid',
  sortBy: defaultSortBy = 'name'
}) {
  const [q, setQ] = useState('')
  const [sortBy, setSortBy] = useLocalStorage(path + '#sortBy', defaultSortBy)
  const [viewType, setViewType] = useLocalStorage(path + '#viewType', defaultViewType)

  const navigate = useNavigate()

  return  (
    <GenericListContext.Provider
      value={{
        path,
        columns,
        q,
        emptyText,
        avatar,
        showDefaultColumns,
        baseUrl,
        setQ,
        sortBy,
        showImages,
        setSortBy,
        viewType,
        setViewType,
        queryKey
      }}
    >
      <Container>
        <Typography level="h1" component="h1">{heading}</Typography>
        <Box sx={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap: 1 }}>
          <Button
            sx={{ transitionDelay: `100ms` }}
            variant="solid"
            color="primary"
            onClick={() => navigate(addUrl)}
            startDecorator={<Plus />}
          >{addLabel}</Button>
          <Input
            sx={{ transitionDelay: `200ms`}}
            startDecorator={<MagnifyingGlass weight="bold" />}
            defaultValue={q}
            onChange={(event) => setQ(event.target.value)}
            placeholder={i18n.t(filterPlaceholder)}
          />
          {canChangeSortBy && (
            <Select
              sx={{ transitionDelay: `300ms`, minWidth: 200 }}
              placeholder={i18n.t('sort-by')}
              defaultValue={sortBy}
              onChange={(event, value) => setSortBy(value)}
            >
              {sortByFields.map(field => (
                <Option value={field.id}>{field.name}</Option>
              ))}
            </Select>
          )}
          {canSelectViewType && <ViewTypeSelect viewType={viewType} onChange={(value) => setViewType(value)} />}
        </Box>
      </Container>
      <br />
      <React.Suspense fallback={<Loading />}>
        <GenericListView Icon={Icon} NodeCardComponent={NodeCardComponent} />
      </React.Suspense>
    </GenericListContext.Provider>
  ) 
}

export function GenericListView({
  NodeCardComponent,
  Icon
}) {
  const hostname = useHostname()
  const {
    queryKey,
    path,
    empty,
    baseUrl,
    showDefaultColumns,
    avatar,
    showImages,
    q,
    columns,
    setQ,
    sortBy,
    emptyText,
    setSortBy,
    viewType,
    setViewType
  } = useContext(GenericListContext)

  const { data, refetch, fetchNextPage, isLoading, isRefetching, isPreviousData } = useInfiniteResource({
    queryKey: [path, q, sortBy],
    path: `${path}`,
    query: {
      q,
      ordering: sortBy
    }
  })

  useEffect(() => {
    refetch()
  }, [q])

  const loading = isLoading || isRefetching

  const nodes = data?.pages?.flat(1) ?? []

  const navigate = useNavigate()

  const handleSortClick = (columnId) => {
    if (sortBy?.replace('-', '') === columnId) {
      if (sortBy.startsWith('-')) {
        setSortBy(sortBy.substr(1))
      } else {
        setSortBy('-' + sortBy)
      }
    } else {
      setSortBy(columnId)
    }
  }
  
  const renderColumn = ({ node, column }) => {
    const value = node[column.id]
    const avatar = column.avatar
    switch(column.type) {
      case "avatar":
        return ( 
          <Avatar src={value} style={{ aspectRatio: '1/1', height: '32pt'}} />
        )
      case "image":
        return value ? (
          <Img 
            src={value}
            style={{ objectFit: 'contain', width: '50pt' }}
          />
        ) : (
          <Icon size={32} />
        )
      default:
        if (column.render instanceof Function) {
          return column.render({ node, column })
        }
        if (column.link) {
          return <Link to={column.getLinkHref({ node, column })}>{value}</Link>
        }
        if (value instanceof Array) {
          return value.map(n => renderColumn({ n, column }))
        } else if (value instanceof Object) {
          if (column.link) {
            return (
              <Link to={column.getLinkHref({ node, column })}>{value.name ?? '-'}</Link>
            )
          } else {
            return value?.name ?? '-'
          }
        }
        return value
    }
  }

  return (
    <Container sx={{ filter: isPreviousData ? 'saturate(0)' : 'none'}}>
      <SwitchCase value={viewType}>
        <Case match="grid">
          <Grid container spacing={2}>
            {loading ? (
              <ForEach collection={new Array(4).fill([1, 2, 3, 4])}>
                <Do>
                  {(n, i) => (
                    <Grid md={3}>
                      <NodeCard sx={{ p: 0, transitionDelay: `${i * 100}ms`}} loading={loading} /> 
                    </Grid>
                  )}
                </Do>
              </ForEach>
            ) : (
              <ForEach collection={nodes}>
                <Do>
                  {(node, i) => (
                    <Grid key={node.id} md={3}> 
                      <NodeCardComponent sx={{ p: 0, transitionDelay: `${i * 100}ms`}} component={Link} to={`${baseUrl}/${node.id}`} {...node} />
                    </Grid>
                  )}
                </Do>
                <Empty>
                  <EmptyView text={emptyText} Icon={Icon} />
                </Empty>
              </ForEach>
            )}
          </Grid>
        </Case>
        <Case match="list">
          <Table width="100%" sx={{ width: '100%'}}>
            <thead>
              <tr>
              {showDefaultColumns && (
                <>
                  <th>
                    
                  </th>
                  <th>
                    {i18n.t('name')}
                  </th>
                </>
              )}
                {columns.map(column => {
                  if (column.sortable) {
                    return (
                      <th key={column.id}>
                        <button style={{ border: 'none', background: 'none' }} onClick={() => handleSortClick(column.id)}>
                          {i18n.t(column.name)} {sortBy?.replace('-', '') === column.id ? (
                            sortBy.startsWith('-') ? (
                              <CaretDown />
                            ) : <CaretUp />
                          ) : null}
                        </button>
                      </th>
                    )
                  }
                  return (
                    <th key={column.id}>{i18n.t(column.name)}</th>
                  )
                })}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ?
                <ForEach collection={new Array(5).fill(5)}>
                  <Do>
                    {((n, i) => (
                      <tr key={`${i}`}>
                        <td>
                          <Box sx={{ position: 'relative', overflow:'hidden', width: '32pt', aspectRatio: '1/1' }}>
                            <Skeleton loading={loading} />
                          </Box>
                        </td>
                        <td>
                          <Typography
                            sx={{ position: 'relative', overflow: 'hidden' }}
                          >
                            <Skeleton loading={loading}>Loading</Skeleton>
                          </Typography>
                        </td>
                        {columns.map(column => (
                          <td key={column.id}>
                            <Typography
                              sx={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Skeleton loading={loading}>Loading</Skeleton>
                            </Typography>
                          </td>
                        ))} 
                        <td style={{ textAlign: 'right'}}>
                          <Typography
                            sx={{ position: 'relative', overflow: 'hidden' }}
                          >
                            <Skeleton loading={loading}>...</Skeleton>
                          </Typography>
                        </td> 
                      </tr>
                    ))}
                  </Do>
                </ForEach>
              :
                <ForEach collection={nodes}>
                  <Do>
                    {(node, i) => (
                      <tr style={{ transitionDelay: `${i * 100}ms`}} key={node.id}>
                        {showDefaultColumns && (
                          <>
                            <td width={10}>   
                              {avatar ? <Avatar src={node.image_url} style={{ aspectRatio: '1/1', height: '32pt'}} /> : 
                                (showImages && node.image_url) ? (
                                  <Img 
                                    src={node.image_url}
                                    style={{ objectFit: 'contain', width: '50pt' }}
                                  />
                                ) : (
                                <Icon size={32} />
                              )}
                            
                            </td>
                            <td>
                              <Typography>{node.name || <i>**No name**</i>}</Typography>
                            </td>
                          </>
                        )}
                        {columns.map(column => (
                          <td key={column.id}>{renderColumn({ column, node })}</td>
                        ))}
                        <td style={{ textAlign: 'right'}}>
                          <Dropdown>
                            <MenuButton
                            sx={{ opacity: 0.5 }}
                              slots={{ root: IconButton }}
                              slotProps={{ root: { color: 'neutral' } }}
                            >
                              <MoreHoriz />
                            </MenuButton>
                            <Menu placement="bottom-end">
                              <MenuItem onClick={() => navigate(`${baseUrl}/${node.id}`)}>  
                                <Eye />{i18n.t('view')}
                              </MenuItem>
                              <MenuItem onClick={() => navigate(`${baseUrl}/${node.id}/edit`)}>  
                                <Pencil />{i18n.t('edit')}
                              </MenuItem>
                              <MenuItem sx={{ color: "danger" }} onClick={() => deleteNode(node.id)}>  
                                <Trash />{i18n.t('delete')}
                              </MenuItem> 
                            </Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    )}
                  </Do>
                  <Empty>
                    <tr>
                      <td style={{ padding: 0 }} colSpan={columns.length + 3}>
                        <EmptyView text={emptyText} Icon={Icon} />
                      </td>
                    </tr>
                  </Empty>
                </ForEach>
              }
            </tbody>
          </Table>
        </Case>
      </SwitchCase>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="outlined" onClick={() => fetchNextPage()}>{i18n.t('load-more')}</Button>
      </Box>
    </Container>
  )
}
