import React, { useState } from 'react';

import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useHostname, useLabel } from '@/contexts';

import { Loading } from '@/components/Loading';

import { deleteRelease } from '@/actions/aloader'

import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from '@/components/Skeleton';

import i18n from '@/i18n';
import { useSearchParams } from 'react-router-dom';

import { Typography, Table, Box, Grid, IconButton, Input, Menu, MenuButton, MenuItem, Select, Chip } from '@mui/joy';
import { Button, Container } from '@mui/joy';
import { Cards, CardsThree, Eye, MagnifyingGlass, Pencil, Plus, Trash } from '@phosphor-icons/react';
import { ReleaseCard } from '@/components/ReleaseCard';
import { Link } from 'react-router-dom';
import { useLocalStorage, useResource } from '@/hooks';
import { Case, Do, ForEach, SwitchCase } from '@/components/Syntax'; 
import { Img } from '@/components/Img';
import { Dropdown } from '@mui/joy';
import { MoreHoriz } from '@mui/icons-material';
import { ViewTypeSelect } from '@/components/ViewTypeSelect';
import { GenericList } from '@/components/GenericList';
import moment from 'moment';

export function ReleasesPage() {
  return (
    <ReleasesView />
  )
}

export function ReleasesView({
  q,
  viewType
}) {
  return (
    <GenericList
      showDefaultColumns={false}
      queryKey={['releases']}
      sortByFields={[
        {
          id: '-released',
          name: i18n.t('recent-to-oldest-release-date')
        },
        {
          id: 'name',
          name: i18n.t('alphabetically')
        },
        {
          id: '-updated',
          name: i18n.t('recent-to-oldest-edit')
        },
      ]}
      columns={[
        {
          id: 'image',
          name: 'image',
          type: 'image',
          sortable: true,
          render: ({ node }) => node.name
        },
        {
          id: 'name',
          name: 'name',
          sortable: true,
          render: ({ node }) => node.name
        },
        {
          id: 'released',
          name: 'released',
          sortable: true,
          render: ({ node }) => moment(node.released).isValid() ? moment(node.released).format('YYYY-MM-DD') : '-'
        },
        {
          id: 'updated',
          name: 'updated',
          sortable: true,
          render: ({ node }) => moment(node.updated).isValid() ? moment(node.released).format('YYYY-MM-DD') : '-'
        },
        {
          id: 'status',
          name: 'status',
          render: ({ node }) => {
            let status = null
            if (node.submitted) {
              status = 'submitted'
              if (moment(node.released).isSameOrBefore(moment())) {
                status = 'released'
              }
            }
            if (status) {
              return (
                <Chip color="primary">{i18n.t(status)}</Chip>
              )
            }
            
          }
        },
        {
          id: 'submitted',
          name: 'submitted',
          render: ({ node }) => {
            return moment(node.submitted).isValid() ? moment(node.submitted).format('YYYY-MM-DD')  : '-'
          }
        }/*,
        {
          id: 'pitch',
          name: 'pitch',
          render: ({ node }) => {
            if (moment(release.submitted).isValid() && moment(node.released).isAfter(moment())) {
              return <Button>Pitch</Button>
            }
          }
        }*/
      ]}
      Icon={CardsThree}
      showImages={true}
      path={`/releases`}
      addLabel={i18n.t('add-release')}
      filterPlaceholder={i18n.t('filter-releases')}
      NodeCardComponent={ReleaseCard}
      heading={i18n.t('releases')}
      addUrl={`/dashboard/release/add`}
      baseUrl={`/dashboard/release`}
    />
  )
}

export default ReleasesPage