import { gql } from '@apollo/client';

import { getAPIUrl, getAccountUrl } from '@/utils';

import localForage from 'localforage';

import client from '../client';

export async function getCurrentUser() {
  try {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: gql`query { 
        me {
          imageUrl
          username
        } 
      }`,
    })
    let user = data?.me
    return user
  } catch (e) {
    throw e
  }
}

export async function startLogin() {
  const {
    protocol,
    hostname,
    port,
    pathname,
    search
  } = window.location
  const redirectTo = `${protocol}//${hostname}:${port}${pathname}?${search}`
  await localForage.setItem('redirectTo', redirectTo)
  const url = '/accounts/login' // `${process.env.NEXT_PUBLIC_LOGIN_URL}?response_type=code&client_id=${process.env.NEXT_PUBLIC_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.NEXT_PUBLIC_REDIRECT_URI)}`
  setTimeout(
    () => {
      window.location.href = url
    },
    500
  )
}

export async function generateTokenFromAuthorizationCode(code) {
  try {
    const response = await fetch(
      `${getAPIUrl()}/auth/api/token`,
      {
        method: 'POST',
        body: JSON.stringify({
          code
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await response.json()
    return data?.tokenAuth
  } catch (e) {
    throw e
  }
}

export async function deleteArtistUsers({
  labelId,
  artistIds
}) {
  try {
    const { data } = await client.mutate({
      mutation: gql`mutation deleteArtistUsers($artistIds: [ID]!, $labelId: ID!) {
        deleteArtistUsers(artistIds: $artistIds, labelId: $labelId) {
          ok
        }
      }`,
      variables: {
        labelId,
        artistIds
      }
    }) 
    return data.deleteArtistUsers
  } catch (e) {
    throw e
  }
}

export async function deleteLabelUsers({
  labelId,
  labelUserIds
}) {
  try {
    const { data } = await client.mutate({
      mutation: gql`mutation deleteLabelUsers($labelUserIds: [ID]!, $labelId: ID!) {
        deleteLabelUsers(labelUserIds: $labelUserIds, labelId: $labelId) {
          ok
        }
      }`,
      variables: {
        labelId,
        labelUserIds
      }
    }) 
    return data.deleteLabelUsers
  } catch (e) {
    throw e
  }
}

export async function getLabelByDomain(domain, subDomain) {
  const response = await fetch(
    `${getAPIUrl()}/api/labels?domain=${domain}&subDomain=${subDomain}`
  )
  const data = await response.json()
  return data
}

export async function generateTokenFromUsernameAndPassword(username, password) {
  try {
    const { data } = await client.mutate({
      mutation: gql`mutation TokenAuth($username: String!, $password: String!) {
        tokenAuth(username: $username, password: $password) {
          token
          payload
          refreshExpiresIn
        }
      }`,
      variables: {
        username,
        password
      }
    })
    if (!data?.tokenAuth) {
      throw new Error("Could not log in");
    }
    return data?.tokenAuth
  } catch (e) {
    throw e
  }
}

export async function loginWithAuthorizationCode(code) {
  const result = await generateTokenFromAuthorizationCode(code)
  const { token } = result
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function loginWithUsernameAndPassword(username, password) {
  const result = await generateTokenFromUsernameAndPassword(username, password)
  const { token } = result
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function beginPasswordReset(email) {
  const formData = new FormData()
  formData.set('email', email)
  try {
    const res = await fetch(
      `${getAccountUrl()}/users/reset_password/`,
      {
        method: 'POST',
        cors: 'no-cors',
        body: formData
      }
    ) 
    const data = await res.json()
    return data
  } catch (e) {
    throw e
  }
}

export async function resetPassword({ uid, token, newPassword, reEnterNewPassword }) {
  const formData = new FormData()
  formData.set('uid', uid)
  formData.set('token', token)
  formData.set('new_password', newPassword)
  formData.set('re_enter_new_password', reEnterNewPassword)
  try {
    const res = await fetch({
      url: `${getAccountUrl()}/users/reset_password_confirm/`,
      method: 'POST',
      body: formData
    })  
    const data = await res.json()
    return data
  } catch (e) {
    throw e
  }
}

export async function logout() {
  await localForage.clear()
  indexedDB.deleteDatabase('keyvaluepairs')
  indexedDB.deleteDatabase('local-forage-detec-blob-support')
}

export async function loginWithJwtToken(token) {
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function getSession() {
  const session = await localForage.getItem('session')

  return session
}

export async function loadCurrentUser() {
  const domain = window?.location?.hostname
  const { data } = await client.query({
    query: gql`
    query getProfile($hostname: String) {
      me {
        imageUrl
        username
        roles {
          id
          role
          label {
            id
            name
            domain
            isBranded
            logotypeUrl
          }
          artists {
            id
            name
          }
        }
        label(domain: $hostname) {
          id
          name
          isBranded
          css
          domain
          color
          logotypeUrl
          imageUrl
          clarityId
          payeeUsers {
            payee {
              id
              name
            }
            user {
              id
              username
            }
          }
        }
      }
    }
  `, 
    variables: {
      hostname: domain
    } 
  })
  return data.me
}

export async function uploadCoverArtToArtist(releaseId, file) {
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/artists/${releaseId}/images`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function uploadCoverArtToRelease(releaseId, file) {
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/releases/${releaseId}/images`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function fetchAudioByRecordingId(recordingId) {
  const token = await localForage.getItem("token");
  const response = await fetch(
    `${getAPIUrl()}/api/recordings/${recordingId}/audio`,
    {
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed fetch upload info");
  }
  const data = response.json()
  return data
}

export async function uploadAudioToRecording(recordingId, file) {
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/recordings/${recordingId}/audio`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
}

export async function uploadFiles(files, onUpdate) {
  // @ts-ignore
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', files[0])
  const response = await fetch(
    `${getAPIUrl()}/leads/api/uploads`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  return await response.json()
}