import { Route } from "react-router-dom";
import AccountsLayout from "./layout";
import { Navigate } from "react-router-dom";
import LoginPage from "./login";
import LogoutPage from "./logout";

export default (
    <Route path="accounts" element={<AccountsLayout />}>
        <Route index element={<Navigate to="login" replace />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="logout" element={<LogoutPage />} />
    </Route>
)