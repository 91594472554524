import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { getLabelByDomain, loadCurrentUser } from '@/actions/login'

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';              
import { ApolloProvider } from '@apollo/client';

import { SnackbarProvider } from 'notistack'
import { AppContext } from '@/contexts/AppContext';
import { SessionContext } from '@/contexts/SessionContext';
import { CssBaseline, CssVarsProvider, GlobalStyles } from '@mui/joy';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import client from '@/client';

import { PlayerContext } from './contexts/PlayerContext';

import { useRef } from 'react';

import router from './router';

import createTheme from '@/theme'
import buildTheme from '@/theme';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from './components/LoadingScreen';

const defaultTheme = createTheme({})

// 2. the persister
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

export const queryClient = new QueryClient()

function App({ Component, pageProps }) {
  const params = useParams()

  const { t } = useTranslation();
  const [messages, setMessages] = useState([])
  const [session, setSession] = useState(null)
  const [label, setLabel] = useState(null)
  const [status, setStatus] = useState(null)
  const [user, setUser] = useState(null)
  const [role, setRole] = useState(null)

  const [theme, setTheme] = useState(defaultTheme)

  const play = () => {
    if (audioEl?.current) {
      audioEl.current.play()
    }
  }
  const pause = () => {
    if (audioEl?.current) {
      audioEl.current.pause()
    }
  }

  const [labelLoading, setLabelLoading] = useState(true)

  const [hostname, setHostname] = useState('localhost:3000')
  
  const [recording, setRecording]  = useState(null)

  const [loading, setLoading] = useState(true)

  const audioEl = useRef(null)
 
  const pathname = window.location.pathname

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const hostname = window.location.hostname
    const subHostname = params.hostname
    getLabelByDomain(hostname, subHostname).then(label => {
      setLabel(label)
      if (label?.isBranded) {
        setTheme(buildTheme({
          themeType: 'label',
          brandPrimary: label.color
        }))
      } else {
        setTheme(buildTheme({
          themeType: 'ALOADED',
          brandPrimary: '#000'
        }))
      }
      setLabelLoading(false)
    })
    if (pathname.indexOf('/accounts') === 0 || pathname === '/' || pathname.indexOf('/invitation') === 0) {
      return
    }
    if (!search.has('code')) {
      loadSession()
    }
       
    if (typeof window !== 'undefined') {
      setHostname(window.location.hostname);
    }
  }, [])

  async function loadSession() {
    try {
      const user = await loadCurrentUser()
      if (!user) {
        throw new Error("User is undefined")
      }
      setUser(user)
      if (user.label) {
        setLabel(user.label)
        setHostname(user?.label?.domain)

        if (user.label.isBranded) {
          setTheme(buildTheme({
            themeType: 'label',
            brandPrimary: user.label.color
          }))
        } else {
          setTheme(buildTheme({
            themeType: 'ALOADED',
            brandPrimary: '#000'
          }))
        }
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return ( 
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <ApolloProvider client={client}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppContext.Provider>
              <PlayerContext.Provider value={{ play, pause, status, recording, setRecording }}>
                <SessionContext.Provider value={{ role, user, setUser, setRole, loadSession, label, setLabel, hostname, setHostname }}>
                  <CssVarsProvider
                    defaultMode="system"
                    // The props below are specific to this demo,
                    // you might not need them in your app.
                    //
                    theme={theme}
                    // the local storage key to use.
                    modeStorageKey="demo_identify-system-mode"
                    // set as root provider
                    disableNestedContext
                  >
                    <GlobalStyles
                      styles={(theme) => {
                        
                        const scrollbarColor = 'var(--variant-borderColor)'
                        return ({
                          '*': {
                            '&::-webkit-scrollbar': {
                              width: '2rem',
                              height: '2rem'
                            },
                          },
                          'body': { 
                          
                            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                              transform: 'scale(1.1%)',
                            },
                            "&::-webkit-scrollbar, & *::-webkit-scrollbar:vertical": {
                              borderLeft: `${theme.variants.outlined.borderWidth} solid ${theme.variants.outlined.neutral.borderColor}`
                            },
                            "&::-webkit-scrollbar, & *::-webkit-scrollbar:horizontal": {
                              borderTop: `${theme.variants.outlined.borderWidth} solid ${theme.variants.outlined.neutral.borderColor}`
                            },
                            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                              borderRadius: 0,
                              minHeight: 24,
                            }, 
                            "&::-webkit-scrollbar-thumb:vertical, & *::-webkit-scrollbar-thumb:vertical": {
                              borderRadius: 0,
                              borderTop: `${theme.variants.outlined.borderWidth} solid ${theme.variants.outlined.neutral.borderColor}`,
                              borderBottom: `${theme.variants.outlined.borderWidth} solid ${theme.variants.outlined.neutral.borderColor}`
                            }, 
                            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                              backgroundColor:  'transparent',
                              borderTop: `${theme.variants.outlined.borderWidth} solid ${theme.variants.outlined.neutral.borderColor}`,
                              borderLeft: `${theme.variants.outlined.borderWidth} solid ${theme.variants.outlined.neutral.borderColor}`,
                            },
                          },
                          ':root': {
                            '--Header-height': '52px',
                            [theme.breakpoints.up('md')]: {
                              '--Header-height': '0px',
                            },
                          },
                        })
                      }}
                    />
                    <CssBaseline />
                    {(label) ? 
                      <RouterProvider router={router} />
                    : <LoadingScreen />}
                  </CssVarsProvider>
                </SessionContext.Provider>
              </PlayerContext.Provider>
            </AppContext.Provider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ApolloProvider> 
    </PersistQueryClientProvider>
  );
}

export default App;